import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/diagram-js.css";
import BpmnViewer from "bpmn-js/lib/NavigatedViewer";
import Canvas from "diagram-js/lib/core/Canvas";
import React, { Component, RefObject, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import StringHelpers from "../../../Helpers/StringHelpers";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import IdComponent from "../../shared/components/id/idComponent";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";

import { CustomBpmnBusinessObject, CustomBpmnEvent } from "./bpmnModels";

interface IProps {
  diagramXML: string;
  legends?: Record<string, string>;
  printMode?: boolean;
}

export function CustomBpmnViewer(props: IProps) {
  const { legends, printMode } = props;
  const [show, setShow] = useState<CustomBpmnBusinessObject>();
  const [error, setError] = useState<unknown>();
  const handleClose = () => setShow(undefined);

  const handleOnClick = (e: CustomBpmnEvent) => {
    if (legends?.[e.element.businessObject.id]) {
      setShow(e.element.businessObject);
    }
  };

  return (
    <>
      {error && <GenericAlert type="danger">{JSON.stringify(error)}</GenericAlert>}
      <Modal show={show != undefined} onHide={handleClose}>
        <Modal.Header closeButton>
          {show && (
            <Modal.Title>
              {show.name} <IdComponent id={show.id} />
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>{legends && show && <FormattedUserInput content={legends[show.id]} />}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <CustomBpmnViewerInner {...props} onClick={handleOnClick} onError={(e) => setError(e)} printMode={printMode} />
    </>
  );
}

interface IState {
  diagramXML?: string;
}

interface InnerProps extends IProps {
  onClick: (e: CustomBpmnEvent) => void;
  onError: (err: unknown) => void;
  printMode?: boolean;
}

class CustomBpmnViewerInner extends Component<InnerProps, IState> {
  bpmnViewer?: BpmnViewer;
  containerRef: RefObject<HTMLDivElement>;
  printMode: boolean;

  constructor(props: InnerProps) {
    super(props);
    this.state = {};
    this.containerRef = React.createRef<HTMLDivElement>();
    this.printMode = props.printMode ?? false;
  }

  async componentDidMount() {
    const { diagramXML } = this.props;
    const container = this.containerRef.current;
    if (!container) throw Error("Container required");
    this.bpmnViewer = new BpmnViewer({ container });
    if (!diagramXML) {
      this.handleError("Missing xml");
    } else {
      await this.displayDiagram(diagramXML);
    }

    this.bpmnViewer.on<CustomBpmnEvent>("element.click", (e) => {
      this.props.onClick(e);
    });

    const overlays = this.bpmnViewer.get("overlays");
    if (this.props.legends) {
      Object.entries(this.props.legends)
        .filter((x) => !StringHelpers.isNullOrWhitespace(x[1]))
        .forEach((cv) => {
          // attach an overlay to a node
          //@ts-expect-error Wait for better typings of bpmn.io
          overlays.add(cv[0], "note", {
            position: {
              bottom: 0,
              right: 0,
            },
            html: '<i title="Click on the node for more info" class="fa-solid fa-circle-info text-info"/>',
          });
        });
    }
  }

  async displayDiagram(diagramXML: string) {
    await this.bpmnViewer?.importXML(diagramXML);
    const canvas = this.bpmnViewer?.get<Canvas>("canvas");
    if (!canvas) throw Error("Missing canvas");
    const { inner } = canvas.viewbox();

    const center = {
      x: inner.x + inner.width / 2,
      y: inner.y + inner.height / 2,
    };

    // unless scaled with the mouse, it fits the DIN A4
    if (this.printMode) {
      canvas.zoom(0.45, { x: 0, y: 0 });
    } else {
      canvas.zoom("fit-viewport", center);
    }

    this.setState({ diagramXML });
  }

  handleError(err: unknown) {
    this.props.onError(err);
  }

  componentWillUnmount() {
    this.bpmnViewer?.destroy();
  }

  render() {
    return <div className="react-bpmn-diagram-container" ref={this.containerRef} style={{ height: "75vh" }}></div>;
  }
}
